<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h4 class="text-center my-3">Дисциплины для педагогической нагрузки (языковой центр)</h4>

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Язык</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="languageId">
            <option v-for="(sLanguage, sLanguageIndex) in [{id: 0, name:'Выберите язык'},...studyLanguages]"
                    :key="sLanguageIndex"
                    :value="sLanguage.id">
              {{ sLanguage.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Уровень обучения</label>
        <div class="col-md-9">
          <select class="form-control form-select" v-model="studyLevelId">
            <option
                v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...docOrdersStudents_form.studyLevels]"
                :value="item.id"
                :key="index">{{ item.name }}
            </option>
          </select>

        </div>
      </div>

      <div class="mt-4 text-center" v-if="studyLevelId">


        <Button label="Вывести" @click="printDisciplines" style="margin-right: 5px"/>
        <Button label="Очистить" class="p-button-warning" @click="clear"/>

      </div>

    </div>
  </div>

  <div class="container-fluid">


    <!-- disciplines table -->
    <div class="mt-4 disciplines" v-if="ppsLoad_form.disciplines.length != 0">


      <DataTable class="disciplines-list" :value="ppsLoad_form.disciplines" :paginator="true" :rows="10" showGridlines
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 filterDisplay="menu"
                 v-model:filters="filters"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" stripedRows
                 responsiveLayout="scroll">

        <Column header="Название дисциплины">
          <template #body="{data}">
            <router-link target="_blank"
                         :to="'/department/combine-teaching-load'+'?discipline_id='+data.education_discipline_id+ '&semester='+data.semester + '&credit='+data.credit">
              {{data.education_discipline_name}}
            </router-link>
          </template>

        </Column>
        <Column header="ОП" style="width: 25%">
          <template #body="{data}">


            <div v-if="data.education_programs.length == 1">

              {{ data.education_programs[0].education_speciality_code }}
              {{ data.education_programs[0].education_speciality_name }} -
              {{ data.education_programs[0].admission_year }}

            </div>
            <div v-else>
              <p>

                <a data-bs-toggle="collapse"
                   :href="'#collapseEpd'+data.education_discipline_id + data.semester + data.credit"
                   aria-expanded="false"
                   :aria-controls="'collapseEpd'+data.education_discipline_id + data.semester + data.credit">
                  Список ОП
                </a>


              </p>
              <div class="collapse" :id="'collapseEpd'+data.education_discipline_id + data.semester + data.credit">

                <div v-for="(item, index) in data.education_programs" :key="index">
<!--                  {{ item.education_speciality_code }} -->
                  {{ item.epd_name }}
<!--                  {{ item.admission_year }}-->
                  <br>
                  <br>
                </div>

              </div>
            </div>


          </template>
        </Column>
        <Column header="Кред.">
          <template #body="{data}">
            <div v-if="data.is_standard == '1'">
              <b style="color: #a99c03">{{ data.credit }}</b>
            </div>
            <div v-else>
              <b style="color: #2e9d00">{{ data.credit }}</b>
            </div>
          </template>
        </Column>
        <Column field="semester" header="Сем.">
          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="semesters" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{ slotProps.value }}</span>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <span>{{ slotProps.option }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column field="students_count" header="Кол. студ."></Column>

        <Column header="Сем. часы">
          <template #body="{data}">
            ×{{ data.seminar_count }} ({{ data.seminar_hours }})
          </template>
        </Column>
        <Column field="exam_hours" header="Экз."></Column>

        <Column header="Преподаватель">
          <template #body="{data}">

            <div v-if="data.pps_teaching_load.length == 0">

              <Button label="Выбрать" @click="openAddPpsLoadDialog(data.education_discipline_code, data.seminar_hours,
                 data.seminar_count, data.students_count, data.education_discipline_id, data.education_discipline_name, data.semester, data.credit, data.lang_parent_id)"
                      style="margin-bottom: 5px"/>
            </div>
            <div v-else>
              <Button label="Обновить" class="p-button-secondary"
                      @click="openUpdatePpsLoadDialog(data.need_computer, data.education_discipline_id, data.education_discipline_name, data.pps_teaching_load, data.pps_id, data.students_count, data.lang_parent_id)"
                      style="margin-bottom: 5px"/>
            </div>


          </template>
        </Column>

      </DataTable>
    </div>
    <!-- end disciplines table -->

  </div>


  <Dialog v-model:visible="addPpsLoadDisplay" style="width: 100%; max-width: 900px" :closable="false">
    <template #header>
      <h5>Добавление преподавателя к дисциплине {{ education_discipline_name }}</h5>
    </template>

    <div class="col-md-12 mt-4">


      <div class="mt-4 seminar-hours" v-if="seminar_hours != 0">
        <h5>Семинарские часы: {{ seminar_hours }} </h5>
        <h5>Остаток количества студентов: {{ getSeminarStudentsCount }}</h5>
        <div class="form-row" v-for="(n, index) in seminar_count" :key="index">


          <div class="form-group row mt-4">
            <label for="seminar_pps_id" class="col-md-3 col-form-label">Преподаватель {{ n }}
              ({{ seminarForm[index]?.hour }})</label>
            <div class="col-md-9" id="seminar_pps_id">
              <select class="form-control form-select"
                      @input="changeSeminar(index, 'pps_id', $event)">
                <option
                    v-for="(item, index) in [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                    :value="item.id"
                    :key="index">{{ item.lastname }} {{ item.firstname }}
                </option>
              </select>
            </div>

          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Количество студентов</label>
            <div class="col-md-9">

              <input type="number" class="form-control"
                     placeholder="Количество студентов"
                     :value="seminarForm[index]?.students_count"
                     @input="changeSeminar(index,'students_count', $event)">
            </div>
          </div>

          <hr>


        </div>


        <div class="mt-4 add-row-seminar-hours">

          <div class="form-row"
               v-for="(seminarPpsTeachingLoadInfo, seminarPpsTeachingLoadInfoIndex) in ppsLoad_form.newSeminarPpsTeachingLoad"
               :key="seminarPpsTeachingLoadInfoIndex">

            <div class="remove-item__button d-flex flex-row-reverse">
              <i class="fa fa-times" @click="deleteRowSeminar(seminarPpsTeachingLoadInfoIndex)"></i>
            </div>

            <div class="form-group row mt-4">
              <label for="pps_id" class="col-md-3 col-form-label">Преподаватель ({{ seminarForm[0].hour }})</label>
              <div class="col-md-9">
                <select class="form-control form-select"
                        @input="changeSeminarPpsTeachingLoadData(seminarPpsTeachingLoadInfo,'pps_id', $event)">
                  <option
                      v-for="(item, index) in [{id: null, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                      :value="item.id"
                      :key="index">{{ item.lastname }} {{ item.firstname }}
                  </option>
                </select>
              </div>
            </div>


            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Количество студентов</label>
              <div class="col-md-9">

                <input type="number" class="form-control"
                       placeholder="Количество студентов"
                       @input="changeSeminarPpsTeachingLoadData(seminarPpsTeachingLoadInfo,'students_count', $event)">
              </div>
            </div>
            <hr>


          </div>

        </div>

        <Button icon="pi pi-plus" @click="addRowSeminarLanguage(seminarForm[0].hour, seminarForm[0].semester, seminarForm[0].credit)"/>
<!--        v-if="getSeminarStudentsCount != 0"-->

      </div>

      <div class="mt-4 exam-hours">
<!--        <h5>Экзаменационные часы: {{ exam_hours }}</h5>-->
        <h5>Экзаменационные часы</h5>
        <div class="form-row" v-for="(n, index) in seminar_count" :key="index">

          <div class="form-group row mt-4">
            <label for="exam_pps_id" class="col-md-3 col-form-label">Преподаватель {{ n }}
              ({{examForm[index]?.hour}})</label>
            <div class="col-md-9" id="exam_pps_id">
              <select class="form-control form-select"
                      @input="changeExam(index, 'pps_id', $event)">
                <!--                    <option selected>Выберите преподавателя</option>-->
                <!--                    [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]-->
                <option
                    v-for="(item, index) in [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                    :value="item.id"
                    :key="index">{{ item.lastname }} {{ item.firstname }}
                </option>
              </select>
            </div>

          </div>

          <hr>


        </div>


        <div class="mt-4 add-row-exam-hours">

          <div class="form-row"
               v-for="(examPpsTeachingLoadInfo, examPpsTeachingLoadInfoIndex) in ppsLoad_form.newExamPpsTeachingLoad"
               :key="examPpsTeachingLoadInfoIndex">

<!--            <div class="remove-item__button d-flex flex-row-reverse">-->
<!--              <i class="fa fa-times" @click="deleteRowExam(examPpsTeachingLoadInfoIndex)"></i>-->
<!--            </div>-->

            <div class="form-group row mt-4">
              <label for="pps_id" class="col-md-3 col-form-label">Преподаватель ({{examForm[0].hour}})</label>
              <div class="col-md-9">
                <select class="form-control form-select"
                        @input="changeExamPpsTeachingLoadData(examPpsTeachingLoadInfo,'pps_id', $event)">
                  <option
                      v-for="(item, index) in [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                      :value="item.id"
                      :key="index">{{ item.lastname }} {{ item.firstname }}
                  </option>
                </select>
              </div>
            </div>


            <hr>


          </div>

        </div>

<!--        <Button icon="pi pi-plus" @click="addRowExam(examForm[0].hour, examForm[0].semester, examForm[0].credit)"/>-->

      </div>


    </div>

    <template #footer>

      <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeAddPpsLoadDialog"/>
      <Button label="Сохранить" icon="pi pi-check" @click="submitPpsTeachingLoad"/>

    </template>
  </Dialog>


  <Dialog v-model:visible="updatePpsLoadDisplay" :style="{width: '50vw'}" :closable="false">
    <template #header>
      <h5>Обновление нагрузки дисциплины {{ update_education_discipline_name }}</h5>
    </template>

    <div class="col-md-12 mt-4">

      <div class="mt-4" v-for="(educationType, educationTypeIndex) in ppsLoad_form.educationType.filter(i=>i.id != 1)"
           :key="educationTypeIndex">
        <h4>{{ educationType.name }}</h4>
        <h5>Остаток количества студентов: {{ getUpdateSeminarStudentsCount }}</h5>

        <div v-for="(ppsLoad, ppsLoadIndex) in ppsTeachingLoadUpdate.filter(i=>i.education_type_id == educationType.id)"
             :key="ppsLoadIndex">

          <div class="remove-item__button d-flex flex-row-reverse">
            <i class="fa fa-times" @click="deleteRowUpdate(ppsLoad)"></i>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Преподаватель {{ppsLoadIndex+1}}</label>
            <div class="col-md-9">
              <select class="form-control form-select"
                      @input="changeUpdatePpsTeachingLoad(ppsLoad, 'pps_id', $event)">
                <option
                    v-for="(item, index) in  [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                    :value="item.id"
                    :selected="item.id == ppsLoad.pps_id"
                    :key="index">{{ item.lastname }} {{ item.firstname }}
                </option>
              </select>
            </div>

          </div>


          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Количество часов</label>
            <div class="col-md-9">

              <input type="number" class="form-control"
                     placeholder="Количество часов"
                     :value="ppsLoad?.hour"
                     @input="changeUpdatePpsTeachingLoad(ppsLoad,'hour', $event)"
                     disabled>
            </div>
          </div>

          <div class="form-group row mt-4" v-if="educationType.id != 3">
            <label class="col-md-3 col-form-label">Количество студентов</label>
            <div class="col-md-9">


              <input type="number" class="form-control"
                     placeholder="Количество студентов"
                     :value="ppsLoad?.students_count"
                     @input="changeUpdatePpsTeachingLoad(ppsLoad,'students_count', $event)">
              <!--              :disabled="need_computer!='1'"-->
            </div>
          </div>
          <hr>

        </div>

        <div class="mt-4 add-row-update-seminar-hours">

          <div class="form-row"
               v-for="(updateSeminarPpsTeachingLoadInfo, updateSeminarPpsTeachingLoadInfoIndex) in ppsLoad_form.newUpdateSeminarPpsTeachingLoad"
               :key="updateSeminarPpsTeachingLoadInfoIndex">

            <div class="remove-item__button d-flex flex-row-reverse">
              <i class="fa fa-times" @click="deleteRowUpdateSeminar(updateSeminarPpsTeachingLoadInfoIndex)"></i>
            </div>

            <div class="form-group row mt-4">
              <label for="pps_id" class="col-md-3 col-form-label">Преподаватель ({{ ppsTeachingLoadUpdate[0].hour }})</label>
              <div class="col-md-9">
                <select class="form-control form-select"
                        @input="changeUpdateSeminarPpsTeachingLoadData(updateSeminarPpsTeachingLoadInfo,'pps_id', $event)">
                  <option
                      v-for="(item, index) in [{id: 0, lastname: 'Выберите преподавателя'}, ...ppsLoad_form.teachers]"
                      :value="item.id"
                      :key="index">{{ item.lastname }} {{ item.firstname }}
                  </option>
                </select>
              </div>
            </div>


            <div class="form-group row mt-4" v-if="educationType.id != 3">
              <label class="col-md-3 col-form-label">Количество студентов</label>
              <div class="col-md-9">

                <input type="number" class="form-control"
                       placeholder="Количество студентов"
                       @input="changeUpdateSeminarPpsTeachingLoadData(updateSeminarPpsTeachingLoadInfo,'students_count', $event)">
              </div>
            </div>
            <hr>


          </div>

        </div>

        <Button v-if="getUpdateSeminarStudentsCount != 0 && educationType.id != 3" icon="pi pi-plus"
                @click="addRowUpdateSeminar(ppsTeachingLoadUpdate[0].hour, ppsTeachingLoadUpdate[0].semester, ppsTeachingLoadUpdate[0].credit)"/>


      </div>
    </div>

    <template #footer>
      <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeUpdatePpsLoadDialog"/>
      <Button label="Обновить" icon="pi pi-check" @click="updatePpsTeachingLoad(ppsTeachingLoadUpdate[0].education_discipline_id, ppsTeachingLoadUpdate[0].semester,
      ppsTeachingLoadUpdate[0].credit)"/>
    </template>
  </Dialog>


</template>


<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex"

export default {
  name: "TeachingLoadLanguageDisciplines",
  data() {
    return {
      loading: true,
      studyLevelId: 0,
      studyCourse: 0,
      languageId: 0,
      filters: {

        'language_name': {value: null, matchMode: FilterMatchMode.EQUALS},
        'semester': {value: null, matchMode: FilterMatchMode.EQUALS},

      },
      studyLanguages: [
        {
          id: 137,
          name: 'русский язык'
        },
        {
          id: 82,
          name: 'қазақ тілі',
        },
        {
          id: 39,
          name: 'English'
        }],

      studyCourses: [
        {
          value: 2,
          name: '2'
        },
        {
          value: 3,
          name: '3'
        },
        {
          value: 4,
          name: '4'
        },
      ],
      semesters: [1, 2, 3, 4, 5, 6, 7, 8],
      addPpsLoadDisplay: false,
      updatePpsLoadDisplay: false,

      seminar_hours: 0,
      seminar_count: 0,
      exam_hours: 0,
      exam_count: 0,
      education_discipline_name: '',
      update_education_discipline_name: '',

      seminarForm: [],
      examForm: [],
      students_count: 0,
      ppsTeachingLoad: [],
      ppsTeachingLoadUpdate: [],
      semester: 0,
      credit: 0,


      education_discipline_code: null,
      addPpsLoadPhysicalEducationDisplay: false,
      need_computer: null,

      updateSeminarForm: [],
      update_students_count: 0
    }
  },
  computed: {
    ...mapState('ppsLoad', ['ppsLoad_form']),
    ...mapState('docOrdersStudents', ['docOrdersStudents_form']),
    ...mapGetters('ppsLoad', ['getRowLectureStudentsCount', 'getRowSeminarStudentsCount', 'getUpdateRowSeminarStudentsCount']),
    getLectureStudentsCount() {
      return this.students_count - parseInt((this.lectureForm.reduce((sum, item) => +item.students_count + sum, 0))) - parseInt(this.getRowLectureStudentsCount)
    },
    getSeminarStudentsCount() {
      return this.students_count - parseInt((this.seminarForm.reduce((sum, item) => +item.students_count + sum, 0))) - parseInt(this.getRowSeminarStudentsCount)
    },
    getUpdateSeminarStudentsCount() {
      return this.update_students_count -
          parseInt((this.ppsLoad_form.updatePpsTeachingLoad.reduce((sum, item) => +item.students_count + sum, 0))) - parseInt(this.getUpdateRowSeminarStudentsCount)
    }


  },
  methods: {
    ...mapActions('ppsLoad', ['GET_LANGUAGE_DISCIPLINES', 'GET_TEACHERS', 'POST_LANGUAGE_PPS_TEACHING_LOAD', 'POST_DISCIPLINE_VACANCY',
      'GET_EDUCATION_TYPE', 'PUT_LANGUAGE_PPS_TEACHING_LOAD', 'POST_FOR_UPDATE_LANGUAGE_PPS_TEACHING_LOAD', 'DELETE_PPS_TEACHING_LOAD']),
    ...mapMutations('ppsLoad', ['CLEAR_DISCIPLINES', 'SET_NEW_LECTURE_PPS_TEACHING_LOAD_DATA', 'SET_NEW_SEMINAR_PPS_TEACHING_LOAD_DATA',
      'SET_NEW_EXAM_PPS_TEACHING_LOAD_DATA', 'ADD_ROW_SEMINAR_LANGUAGE',
      'SET_EDUCATION_DISCIPLINE_ID', 'ADD_ROW_SEMINAR', 'DELETE_ROW_SEMINAR_LANGUAGE', 'CLEAR_STATE',
      'DELETE_ROW_UPDATE_LANGUAGE',
      'SET_UPDATE_PPS_TEACHING_LOAD', 'CLEAR_UPDATE_PPS_TEACHING_LOAD', 'ADD_ROW_UPDATE_SEMINAR',
      'DELETE_ROW_UPDATE_SEMINAR', 'SET_UPDATE_SEMINAR_PPS_TEACHING_LOAD_DATA', 'SET_UPDATE_PPS_TEACHING_LOAD_DATA']),
    ...mapActions('docOrdersStudents', ['GET_STUDY_LEVELS']),


    changeUpdateSeminarPpsTeachingLoadData(item, property, e, val = 'value') {
      const value = e.target[val]
      this.SET_UPDATE_SEMINAR_PPS_TEACHING_LOAD_DATA({item, property, value})
    },

    isSeminarPpsSelected() {
      if (this.seminarForm[0].pps_id === 0) {
        return false
      } else {
        return true
      }
    },


    getSeminarHours() {
      if (this.seminarForm[0].pps_id == 0 || this.seminarForm[0].seminar_hour == 0) {
        return this.seminar_hours
      } else {
        return parseInt(this.seminar_hours) - parseInt((this.seminarForm.reduce((sum, item) => +item.seminar_hour + sum, 0)))
      }

    },
    async printDisciplines() {
      await this.GET_LANGUAGE_DISCIPLINES({languageId: this.languageId, studyLevelId: this.studyLevelId})
    },
    clear() {
      this.studyCourse = 0
      this.studyLevelId = 0
      this.CLEAR_DISCIPLINES()
    },
    openAddPpsLoadDialog(education_discipline_code, seminar_hours, seminar_count, students_count, education_discipline_id, education_discipline_name, semester, credit, lang_parent_id) {
      this.addPpsLoadDisplay = true
      this.education_discipline_code = education_discipline_code
      console.log(this.education_discipline_code)
      console.log(education_discipline_id)

      this.seminar_hours = seminar_hours
      this.seminar_count = seminar_count
      //this.exam_hours = exam_hours
      this.exam_count = seminar_count


      this.education_discipline_name = education_discipline_name
      this.semester = semester
      this.credit = credit

      this.students_count = students_count
      console.log(this.students_count)


      let seminar_students_count = Math.floor(this.students_count / seminar_count)

      const groupsSeminar = []
      for (let i = 0; i < seminar_count; i++) {
        groupsSeminar.push(seminar_students_count)
      }

      let seminarMod = this.students_count % seminar_count

      for (let k = 0; k < seminarMod; k++) {
        groupsSeminar[k] += 1
      }


      for (let i = 0; i < this.seminar_count; i++) {


        this.seminarForm.push({
          pps_id: null,
          hour: seminar_hours / seminar_count,
          students_count: groupsSeminar[i],
          education_discipline_id: education_discipline_id,
          education_type_id: 2,
          semester: this.semester,
          credit: this.credit,
          is_predictive: 0
        })

      }
      console.log(this.seminarForm, 'seminarForm')


      for (let i = 0; i < this.exam_count; i++) {
        this.examForm.push({
          pps_id: null,
          //hour: exam_hours / this.exam_count,
          hour: 15,
          education_discipline_id: education_discipline_id,
          education_type_id: 3,
          semester: this.semester,
          credit: this.credit,
          is_predictive: 0
        })
      }
      console.log(this.examForm, 'examForm')


      this.SET_EDUCATION_DISCIPLINE_ID(education_discipline_id)
      this.GET_TEACHERS(lang_parent_id)
    },
    closeAddPpsLoadDialog() {
      this.addPpsLoadDisplay = false
      this.seminarForm = []
      this.examForm = []

      this.education_discipline_code = null
      this.seminar_hours = 0
      this.seminar_count = 0
      this.semester = 0
      this.credit = 0
      this.CLEAR_STATE()
    },


    openUpdatePpsLoadDialog(need_computer, education_discipline_id, update_education_discipline_name, ppsTeachingLoad, pps_id, update_students_count, lang_parent_id) {

      this.updatePpsLoadDisplay = true
      this.ppsTeachingLoadUpdate = ppsTeachingLoad
      this.SET_EDUCATION_DISCIPLINE_ID(education_discipline_id)
      this.SET_UPDATE_PPS_TEACHING_LOAD_DATA(this.ppsTeachingLoadUpdate)
      this.update_education_discipline_name = update_education_discipline_name
      this.need_computer = need_computer
      this.update_students_count = update_students_count
      console.log(this.need_computer, 'update need_computer')
      console.log(this.ppsTeachingLoadUpdate, 'update ppsTeachingLoadUpdate')
      console.log(this.update_students_count, 'update update_students_count')



      this.GET_TEACHERS(lang_parent_id)
    },
    closeUpdatePpsLoadDialog() {
      this.updatePpsLoadDisplay = false
      this.update_education_discipline_name = ''
      this.education_discipline_id = 0
      this.update_students_count = 0
      this.ppsTeachingLoadUpdate = []
      this.CLEAR_UPDATE_PPS_TEACHING_LOAD()

    },


    changeSeminarPpsTeachingLoadData(item, property, e, val = 'value') {
      const value = e.target[val]
      this.SET_NEW_SEMINAR_PPS_TEACHING_LOAD_DATA({item, property, value})
    },
    changeExamPpsTeachingLoadData(item, property, e, val = 'value') {
      const value = e.target[val]
      this.SET_NEW_EXAM_PPS_TEACHING_LOAD_DATA({item, property, value})
    },
    changeExam(index, property, e, val = 'value') {
      this.examForm[index][property] = e.target[val]
      console.log(this.examForm, 'examForm')
    },

    changeSeminar(index, property, e, val = 'value') {
      this.seminarForm[index][property] = e.target[val]
      console.log(this.seminarForm, 'seminarForm')
    },

    addRowSeminarLanguage(hour, semester, credit) {
      this.ADD_ROW_SEMINAR_LANGUAGE({hour, semester, credit});
    },
    addRowUpdateSeminar(hour, semester, credit) {
      this.ADD_ROW_UPDATE_SEMINAR({hour, semester, credit});
    },
    changeUpdatePpsTeachingLoad(ppsLoad, property, e, val = 'value') {
      const value = e.target[val]
      this.SET_UPDATE_PPS_TEACHING_LOAD({ppsLoad, property, value})
    },

    async deleteRowSeminar(index) {
      await this.DELETE_ROW_SEMINAR_LANGUAGE(index);
    },
    async deleteRowUpdateSeminar(index) {
      await this.DELETE_ROW_UPDATE_SEMINAR(index);
    },
    async deleteRowUpdate(ppsLoad) {

      let success = true
      if (ppsLoad.id) {
        success = await this.DELETE_PPS_TEACHING_LOAD(ppsLoad.id)
      }

      if (success) {
        await this.DELETE_ROW_UPDATE_LANGUAGE({ppsLoad})
      }


    },

    async submitPpsTeachingLoad() {
      this.addPpsLoadDisplay = false
      let data = await this.POST_LANGUAGE_PPS_TEACHING_LOAD({
        seminarForm: this.seminarForm,
        examForm: this.examForm
      })
      if (data) {

        //await this.POST_DISCIPLINE_VACANCY()
        this.seminarForm = []
        this.examForm = []
        this.education_discipline_code = null

        this.seminar_hours = 0
        this.seminar_count = 0
        this.exam_hours = 0
        this.education_discipline_name = ''
        this.semester = 0
        this.credit = 0
        this.CLEAR_STATE()
        await this.GET_LANGUAGE_DISCIPLINES({languageId: this.languageId, studyLevelId: this.studyLevelId})
        this.$message({text: 'Преподаватели привязаны'})
      }

    },
    async updatePpsTeachingLoad() {
      let data = await this.PUT_LANGUAGE_PPS_TEACHING_LOAD()
      if (data) {
        this.updatePpsLoadDisplay = false

        await this.GET_LANGUAGE_DISCIPLINES({languageId: this.languageId, studyLevelId: this.studyLevelId})
        this.$message({text: 'Нагрузка обновлена'})
      }



      let createData = await this.POST_FOR_UPDATE_LANGUAGE_PPS_TEACHING_LOAD()
      if (createData) {
        this.updatePpsLoadDisplay = false
        this.CLEAR_UPDATE_PPS_TEACHING_LOAD()
        await this.GET_LANGUAGE_DISCIPLINES({languageId: this.languageId, studyLevelId: this.studyLevelId})
        this.$message({text: 'Нагрузка создана'})
      }
    },
    // async updatePpsTeachingLoad(education_discipline_id, semester, credit) {
    //
    //   let discipline = this.ppsLoad_form.disciplines.find(i => i.education_discipline_id == education_discipline_id && i.semester == semester && i.credit == credit)
    //   let putForm = discipline.pps_teaching_load.filter(i => !!i.id)
    //
    //   let putStatus = await this.PUT_LANGUAGE_PPS_TEACHING_LOAD(putForm)
    //
    //
    //   let postForm = discipline.pps_teaching_load.filter(i => !i.id)
    //
    //   let postStatus = await this.POST_FOR_UPDATE_LANGUAGE_PPS_TEACHING_LOAD(postForm)
    //
    //
    //   if (putStatus && postStatus) {
    //     this.updatePpsLoadDisplay = false
    //     this.CLEAR_STATE()
    //     await this.GET_LANGUAGE_DISCIPLINES({languageId: this.languageId, studyLevelId: this.studyLevelId})
    //     this.$message({text: 'Нагрузка сохранена'})
    //   }
    //
    //
    // },


  },
  async mounted() {
    this.loading = false
    await this.CLEAR_DISCIPLINES()
    await this.GET_STUDY_LEVELS()
    await this.GET_EDUCATION_TYPE()
  }
}
</script>

<style scoped>
.disciplines-list.p-datatable .p-datatable-thead > tr > th {
  width: 100px !important;
}
</style>